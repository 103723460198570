// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('magnific-popup');
require('slideout');

import '../stylesheets/application';

$(document).on('turbolinks:load', function () {
  // magnificPopup Modal Window -->
  $('.popup-modal').magnificPopup({
    type: 'inline',

    fixedContentPos: false,
    fixedBgPos: true,

    overflowY: 'auto',

    closeBtnInside: true,
    preloader: false,

    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-zoom-in',

    focus: '#username',
    modal: true,
  });

  $('.popup-modal-dismiss').click(function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  $(document).ready(function () {
    validate();
    $('#calculate_request_email, #calculate_request_phone').change(validate);
  });

  function validate() {
    if (
      $('#calculate_request_email').val().length > 0 ||
      $('#calculate_request_phone').val().length > 0
    ) {
      $('input[type=submit]').prop('disabled', false);
    } else {
      $('input[type=submit]').prop('disabled', true);
    }
  }

  // <-- magnificPopup Modal Window

  // Smooth Scrolling -->
  $('.menu-list .scrollable a, #burger-menu a').click(function (e) {
    e.preventDefault();
    var sectionTo = $(this).attr('href');
    $('html, body').animate(
      {
        scrollTop: $(sectionTo).offset().top,
      },
      1500
    );
  });
  // <-- Smooth Scrolling

  // Sidebar Menu Slideout.js -->
  var slideout = new Slideout({
    panel: document.getElementById('content'),
    menu: document.getElementById('burger-menu'),
    padding: 256,
    tolerance: 70,
    side: 'right',
    easing: 'cubic-bezier(.32,2,.55,.27)',
  });

  $('a.burger').click(function () {
    slideout.toggle();
  });

  function close(eve) {
    eve.preventDefault();
    slideout.close();
  }

  slideout
    .on('beforeopen', function () {
      this.panel.classList.add('panel-open');
    })
    .on('open', function () {
      this.panel.addEventListener('click', close);
    })
    .on('beforeclose', function () {
      this.panel.classList.remove('panel-open');
      this.panel.removeEventListener('click', close);
    });
  // <-- Sidebar Menu Slideout.js
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true);
const videos = require.context('../videos', true);
// const imagePath = (name) => images(name, true)
